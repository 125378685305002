* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #fff;
  overflow-x: hidden; }

.v-header {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff; }

.container {
  max-width: 960px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  text-align: center; }

.fullscreen-video-wrap {
  background-image: url("../img/locales.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden; }

.fullscreen-video-wrap video {
  min-height: 100%;
  min-width: 100%; }

.header-overlay {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  background: #000;
  opacity: 0.5; }

.header-content {
  z-index: 2;
  opacity: 0;
  -webkit-animation-name: show-header;
          animation-name: show-header;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes show-header {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show-header {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.header-content h1 {
  font-size: 50px;
  margin-bottom: 0; }

.header-content p {
  font-size: 1.2rem;
  display: block;
  padding-bottom: 2rem; }

.btn {
  background: #f26722;
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5px; }

.btn:hover {
  background: #f8931d; }

.section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.section-b {
  margin: 0;
  margin-left: -1px;
  width: 40%;
  background: #404040;
  color: #fff; }
  .section-b form {
    width: 80%;
    margin: auto;
    padding: 1em 0; }
    .section-b form input[type=number]::-webkit-inner-spin-button,
    .section-b form input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .section-b form input[type=number] {
      -moz-appearance: textfield; }
    .section-b form h2 {
      margin-bottom: 0.5em;
      font-size: 30px;
      font-family: centurygothic, CenturyGothic, AppleGothic, sans-serif;
      text-align: center; }
    .section-b form p {
      margin: 0px; }
    .section-b form label {
      display: block;
      float: left;
      color: #fff; }
    .section-b form input {
      height: 2rem;
      margin-bottom: 1em;
      border-radius: 7px;
      -webkit-box-shadow: 0px 3px 10px 1px #727376;
              box-shadow: 0px 3px 10px 1px #727376; }
    .section-b form textarea {
      border-radius: 7px;
      -webkit-box-shadow: 0px 3px 10px 1px #727376;
              box-shadow: 0px 3px 10px 1px #727376; }
    .section-b form button {
      height: 2rem;
      margin-top: 2em;
      border: 0;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      background: #f26722;
      /* For browsers that do not support gradients */ }
    .section-b form button:hover, .section-b form button:focus {
      background: #f8931d;
      color: #fff;
      outline: 0;
      -webkit-transition: background-color 0.1s ease-out;
      transition: background-color 0.1s ease-out; }
    .section-b form button, .section-b form input, .section-b form textarea {
      width: 100%; }
  .section-b h6 {
    color: #fff;
    font-size: 0.6rem;
    text-align: center; }

.section-a {
  margin: 0;
  padding: 0;
  width: 80%; }
  .section-a img {
    width: 100%;
    height: 100%; }

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none; }

.promo-text {
  width: 90vw;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 7px;
  color: #666;
  font-style: italic;
  font-stretch: expanded;
  text-align: justify; }
